import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo-cr.png"
import { Container } from "reactstrap"
import Menu from "./menu"
// import ExampleMenu from "./menu-original"

const Header = ({ siteTitle }) => (

  <header>
    <Container>
      <Menu/>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
