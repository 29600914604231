import React from "react"
import { Container,Row,Col } from "reactstrap"
import {FaFacebookSquare,FaYoutubeSquare,FaInstagramSquare} from "react-icons/fa"

const Footer = ()=>{
    return(
        <div className="bg-dark text-white p-5">
            <Container>
                <Row className="justify-content-between align-items-baseline">
                    <Col>
                        <p>All Rights Reserved &copy; <a className="text-white" href="https://www.recticart.com" target="_blank" rel="noopener"><u>Recticart</u></a></p>
                        <p>Reach us Out @<a className="text-white" href="tel:+919626330185"><u>9626330185</u></a></p>
                    </Col>
                    <Col className="text-center">
                        <p><strong><u>Follow Us in Social</u></strong></p>
                        <a className="text-color-secondary" href="https://www.facebook.com/recticart">
                            <FaFacebookSquare size={55} className="px-2"/>
                            <small className="text-white">Facebook</small>
                        </a>
                        <a className="text-color-secondary" href="https://www.instagram.com/recticart">
                            <FaInstagramSquare size={55} className="px-2"/>
                            <small className="text-white">Instagram</small>
                        </a>
                        <a className="text-color-secondary" href="https://www.youtube.com/recticart">
                            <FaYoutubeSquare size={55} className="px-2"/>
                            <small className="text-white">YouTube</small>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Footer 