import React, { Component } from 'react';
import {
Collapse,
Navbar,
NavbarToggler,
NavbarBrand,
Nav,
NavItem,
NavLink,
} from 'reactstrap';
import Logo from "../images/logo-cr.png"
import $ from "jquery"

const links = [
{ href: '#', text: 'Home' },
{ href: '#services', text: 'Our Services' },
{ href: '#how-works', text: 'How it Works' },
{ href: '#contact', text: 'Contact US', className: 'bg-color-primary text-white rounded-pill px-3' },
];

const createNavItem = ({ href, text, className }) => (
<NavItem>
    <NavLink href={href} className={className}>{text}</NavLink>
</NavItem>
);

export default class Menu extends Component {
    componentDidMount() {
        $(window).scroll(function() {
            if ($(window).scrollTop() > 10) {
                $('.navbar').addClass('past-main fixed-top')
            } else {
                $('.navbar').removeClass('past-main fixed-top')
            }
        })
        // $('#showMenuBtn').on('click', function() {
        //   $('#navbarSupportedContent').toggleClass('show')
        // })
        // $('.scroll-down').click(function(event) {
        //   event.preventDefault()
        //   $('html,body').animate(
        //     { scrollTop: $(this.hash).offset().top - 100 },
        //     800
        //   )
        // })
        // $('#showDocNavBtn').on('click', function() {
        //   $('#docNavLinkContent').toggleClass('show')
        // })
        // var dropdown = document.getElementsByClassName('dropdown-btn')
        // var i

        // for (i = 0; i < dropdown.length; i++) {
        //   dropdown[i].addEventListener('click', function() {
        //     this.classList.toggle('active')
        //     var dropdownContent = this.nextElementSibling
        //     if (dropdownContent.style.display === 'block') {
        //       dropdownContent.style.display = 'none'
        //     } else {
        //       dropdownContent.style.display = 'block'
        //     }
        //   })
        // }
    }
    constructor(props) {
        super(props);

        this.state = {
        isOpen: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
        isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
        <div>
            <Navbar color="white" expand="md">
                <NavbarBrand href="/">
                    <img src={Logo} className="img"/>
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        {links.map(createNavItem)}
                    </Nav>
                </Collapse>
            </Navbar>
        </div>
        );
    }
}
