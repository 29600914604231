import React from "react"
import { Container, Row , Col} from "reactstrap"

const TopBanner =()=>{
    return(
        <div className="fixed-top">
            <Container>
                <Row>
                    <Col>
                        <p>Call Us Now</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default TopBanner